
// Include any default variable overrides here (though functions won't be available)

//We have to import the functions first to use map.merge()

@import '../../node_modules/bootstrap/scss/functions';

// We have to import the variables

@import '../../node_modules/bootstrap/scss/variables';

// color-palette
$new-theme-colors: (
    "superDarkBlue": #0C0E23,
    "darkBlue": #0C1231,
    "semiBlue": #112A41,
    "actualBlue": #42A4FF,
    "superLightBlue": #D7E6F4,
    "normalGray": #B8C2CC,
    "textGray": #646060,
    "lightGray": #EBEBEB,
    "transparentBlue": #0084ff2d,
    "gold": #E5B80B
);

$theme-colors: map-merge($theme-colors, $new-theme-colors);

$enable-negative-margins: true;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// Then add additional custom code here
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// additional styles
.box-shadow1 {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

// carousel styles

.c-item {
    height: 500px;
}

.c-item > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(0.4);
}
  
// nuka-carousel css styles
.team-carousel button {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin: 0 10px 0;
}

.team-carousel .leftRightBtn {
    padding: 0px;
    font-size: 30px;
    color: white;
    font-weight: bold;
    background-color: #033876;
    border-radius: 50%;
}

.team-carousel .slider-control-bottomcenter button {
    background: white !important;
    border-radius: 50%;
    width: 15px !important;
    height: 15px !important;
}

.team-carousel .slider-control-bottomcenter svg {
    display: none !important;
}

.team-carousel .slider-control-bottomcenter li {
    margin-right: 5px;
}

.linear-transparency {
    background-image: linear-gradient(to bottom, transparent 20%, white 80%)
}
